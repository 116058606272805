import React from "react"
import { signupUrl } from "../../utils/urlHelper"
import { plans } from 'configs/plans'
import { Button, Popover } from 'components/ui'
import "./pricingQuick.scss"

const PricingQuick = (props) => {
  let planIndex = 1
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-10">
      <div className="rounded-lg shadow-pricing divide-y divide-gray-200 flex flex-col">
        <div className="p-8 flex flex-col items-center">
          <div className="rounded-full plan-label py-1 px-3 inline uppercase text-sm font-bold tracking-wider">Essential</div>
          <div className="mt-8 text-lg">For automating backups with minimal needs</div>
          <div className="h-4 mt-6"></div>
          <div className="plan-price">
            <span></span>
            <span className="font-black">
              ${(props.billingPeriod === 'MONTH') ? plans[planIndex].priceMonthly : plans[planIndex].priceYearly}
            </span>
            <span className="opacity-75">/mo</span>
          </div>
          <div className="button-container mt-6">
            <Button
              className="block font-black"
              size="medium"
              color="primary"
              href={ signupUrl({sb_source: "website", sb_term: "pricing", sb_plan: 'startup'}) }
            >Start Free trial</Button>
          </div>
        </div>
        <div className="text-left text-lg bg-gray-50 p-8 rounded-b-lg flex-grow">
            <ul className="m-0 plan-feature-list">
              <li className="flex items-center">
                <i className="fa fa-check text-green  mr-3"/>
                <p>20 <Popover content="Number of backups you can configure. We neither limit the number of backup runs, nor the number of storage or server your connect." className="inline font-light">Backup Jobs</Popover><p className="text-sm">Add more when needed</p></p>
              </li>
              <li><i className="fa fa-check text-green  mr-3"/>Up to 12-hours Backup Interval</li>
              <li><i className="fa fa-check text-green  mr-3"/>Serveless backups</li>
              <li className="flex items-center">
                <i className="fa fa-check text-green  mr-3"/>
                <p>Included Simple Storage provider <p className="text-sm">Unlimited "bring your own" storage</p></p>
              </li>
            </ul>
        </div>
      </div>

      <div className="rounded-lg shadow-pricing divide-y divide-gray-200 flex flex-col border-4 border-solid border-primary">
        <div className="p-8 flex flex-col items-center">
          <div className="rounded-full plan-label plan-label--primary py-1 px-3 inline uppercase text-sm font-bold tracking-wider">Standard</div>
          <div className="mt-8 text-lg">For teams that need higher level of flexibility for their backups.</div>
          <div className="h-4 mt-6"></div>
          <div className="plan-price">
            <span className=""></span>
            <span className="font-black">
              ${(props.billingPeriod === 'MONTH') ? plans[++planIndex].priceMonthly : plans[++planIndex].priceYearly}
            </span>
            <span className="opacity-75">/mo</span>
          </div>
          <div className="button-container mt-6">
            <Button
              className="block font-black "
              size="medium"
              color="primary"
              href={ signupUrl({sb_source: "website", sb_term: "pricing", sb_plan: 'agency'}) }
            >Start Free trial</Button>
          </div>
        </div>
        <div className="text-left text-lg bg-gray-50 p-8 rounded-b-lg flex-grow">
            <ul className="m-0 plan-feature-list">
              <li>Everything in <span className="font-bold">Essential</span> with:</li>
              <li className="flex items-center">
                <i className="fa fa-check text-green  mr-3"/>
                <p>50 <Popover content="Number of backups you can configure. We neither limit the number of backup runs, nor the number of storage or server your connect." className="inline font-light">Backup Jobs</Popover><p className="text-sm">Add more when needed</p></p>
              </li>
              <li><i className="fa fa-check text-green  mr-3"/>Up to 1-hour Backup Interval</li>
              <li><i className="fa fa-check text-green  mr-3"/>Team Members & <Popover content="Projects can be used to group and isolate access to backups to selected team members. Useful when managing backups for your customers!" className="inline font-light">Projects</Popover></li>
              <li><i className="fa fa-check text-green  mr-3"/><Popover content="Incremental backups, compression control, custom scripts, automation ...">Advanced Backup Features</Popover></li>
            </ul>
        </div>
      </div>

      <div className="rounded-lg shadow-pricing divide-y divide-gray-200 flex flex-col">
        <div className="p-8 flex flex-col items-center">
          <div className="rounded-full plan-label py-1 px-3 inline uppercase text-sm font-bold tracking-wider">Advanced</div>
          <div className="mt-8 text-lg">For advanced backup automation and need for scale</div>
          <div className="h-4 mt-6"></div>
          <div className="plan-price">
            <span></span>
            <span className="font-black">
              ${(props.billingPeriod === 'MONTH') ? plans[++planIndex].priceMonthly : plans[++planIndex].priceYearly}
            </span>
            <span className="opacity-75">/mo</span>
          </div>
          <div className="button-container mt-6">
            <Button
              className="block font-black"
              size="medium"
              color="primary"
              href={ signupUrl({sb_source: "website", sb_term: "pricing", sb_plan: 'business'}) }
            >Start free trial</Button>
          </div>
        </div>
        <div className="text-left text-lg bg-gray-50 p-8 rounded-b-lg flex-grow">
            <ul className="m-0 plan-feature-list">
              <li>Everything in <span className="font-bold">Standard</span> with:</li>
              <li className="flex items-center">
                <i className="fa fa-check text-green  mr-3"/>
                <p>200 <Popover content="Number of backups you can configure. We neither limit the number of backup runs, nor the number of storage or server your connect." className="inline font-light">Backup Jobs</Popover><p className="text-sm">Add more when needed</p></p>
              </li>
              <li><i className="fa fa-check text-green  mr-3"/>Up to 1-minute Backup Interval</li>
              <li><i className="fa fa-check text-green  mr-3"/>
              <a className="" target="_blank" rel="noopener noreferrer" href="https://simplebackups.docs.apiary.io">API</a> and Advanced Automation</li>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default PricingQuick
