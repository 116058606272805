import React from "react"
import { signupUrl } from "../../utils/urlHelper"
import { plans, featureGroups, features } from 'configs/plans'
import { Button, Popover, TableStyles } from 'components/ui'
import styled from 'styled-components'
import tw from "tailwind.macro"
import filter from 'lodash/filter'

const SPlans = styled.div`
  ${tw`mt-16`}
  position: relative;
`
const SGroupLabel = TableStyles.SGroupLabel;
const SPlanRow = TableStyles.STableRow;
const SPlanHead = TableStyles.STableHead;
const SPlanFeatures = TableStyles.STableBody;
const SPlan = styled.div`
  display: flex;
  flex-direction: column;
  ${tw`pl-8 pr-8`}
  > div:first-child {
    text-transform: uppercase;
    letter-spacing: 0px;
    ${tw`text-base font-medium mb-3`}
  }
  > div:nth-child(2) {
      span:nth-child(2) {
      ${tw`text-3xl font-bold`}
    }
    ${tw`text-sm font-light`}
  }
  .price-plan__button {
    ${tw`hidden md:inline-block`}
    line-height:2.2rem;
  }
`

const renderPlanFooter = (plans, billingPeriod) => {
  let output = []
  output.push(<div></div>)
  plans.map((plan) => {
    output.push(
      <SPlan className="price-plan">
        <div className="text-bold">{plan.name}</div>
        <div><span></span><span>${(billingPeriod === 'MONTH') ? plan.priceMonthly : plan.priceYearly}</span>/mo</div>
        <Button
          className="price-plan__button font-black mb-3 mt-3"
          href={signupUrl({sb_source: "website", sb_term: "pricing", sb_plan: plan.id})}
          size="small"
          color={(plan.main === true) ? 'primary' : 'primary'}>Start Trial</Button>
      </SPlan>
    )
    return plan
  })

  return output
}

const renderPlanHead = (plans, billingPeriod) => {
  let output = []
  output.push(<div></div>)
  plans.map((plan) => {
    output.push(
      <SPlan className="price-plan">
        <div className="text-bold">{plan.name}</div>
        <div><span></span><span>${(billingPeriod === 'MONTH') ? plan.priceMonthly : plan.priceYearly}</span>/mo</div>
        <Button
          className="price-plan__button font-black mb-3"
          href={signupUrl({sb_source: "website", sb_term: "pricing", sb_plan: plan.id})}
          size="small"
          color={(plan.main === true) ? 'primary' : 'primary'}>Start Trial</Button>
      </SPlan>
    )
    return plan
  })

  return output
}

const renderPlanFeatures = (featureGroups, features) => {
  let output = []
  featureGroups.map((group) => {
    let groupOutput = []
    groupOutput.push(<SGroupLabel>{group.label}</SGroupLabel>)
    plans.map((plan) => {
      groupOutput.push(<div></div>)
      return plan
    })
    output.push(<SPlanRow>{groupOutput}</SPlanRow>)

    // Get features of current group
    let groupFeatures = filter(features, {'group': group.id})
    groupFeatures.map((feature) => {
      let featureOutput = []
      if (feature.helper.length > 1) {
        featureOutput.push(
          <div>
            <Popover content={feature.helper} className="inline font-light">
              {feature.label}{!!feature.new? (<span className="ml-1 bg-green rounded uppercase text-white" style={{'fontSize': '0.6rem', 'padding': '1px 4px'}}>New</span>) : ''}
            </Popover>
          </div>
        )
      } else {
        featureOutput.push(<div className="font-light">{feature.label}{!!feature.new? (<span className="ml-1 bg-green rounded text-white" style={{'fontSize': '0.55rem', 'padding': '1px 2px'}}>New</span>) : ''}</div>)
      }
      plans.map((plan) => {
        featureOutput.push(
          <div className={(plan.main === true) ? 'text-purple' : ''}>
            {(feature.plans[plan.id] === true) ? (<i className="fa fa-check text-green"></i>) : ((feature.plans[plan.id] === false) ? (<>-</>) :  <div className="font-semibold" dangerouslySetInnerHTML={{__html: feature.plans[plan.id]}}/>)}</div>)
        return plan
      })
      output.push(<SPlanRow>{featureOutput}</SPlanRow>)

      return feature
    })
    return group
  })

  return (output)
}

const PricingTable = (props) => {
  return (
    <div {...props}>
      <SPlans>
        <SPlanHead>{renderPlanHead(plans, props.billingPeriod)}</SPlanHead>
        <SPlanFeatures>{renderPlanFeatures(featureGroups, features)}</SPlanFeatures>
        <SPlanHead>{renderPlanFooter(plans, props.billingPeriod)}</SPlanHead>
      </SPlans>
    </div>
  )
}

export default PricingTable
