import PricingPage from "./pricing/_pricingPage";

export default PricingPage

export const frontmatter = {
  pageMetaData: {
    refKey: "pricing",
    title: "SimpleBackups Pricing | Free Forever, Unlimited, & Business Plans",
    description: "Backup your servers, databases, storage and application. Start with a free plan and upgrade when necessary. No credit card required upfront.",
  }
}