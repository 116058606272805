import React, { useState } from "react"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import PricingTable from "./_pricingTable"
import PricingQuick from "./_pricingQuick"
import { TestimonialSection } from "components/sections"
import { Typography, Container, Section, Faqs, Row, SectionTitle } from "components/ui"
import { Button } from 'components/ui'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from 'styled-components'
import tw from "tailwind.macro"
import {theme} from '../../../tailwind.config'
import "./pricingPage.scss"
import {signupUrl} from "../../utils/urlHelper";

const SPeriodSwitcherButton = styled.div`
  ${tw`ml-6 mr-6 text-lg`}
  cursor: pointer;
  position: relative;
  > span {
    ${tw`text-sm`}
    letter-spacing: 0;
  }
  &.active:after {
    bottom: -4px;
    left: 0;
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: 2px solid ${theme.extend.colors.text};
  }
`

const SPeriodSwitcher = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media(max-width: ${theme.extend.screenSizes.md.max}) {
    & ${SPeriodSwitcherButton} {
      margin-bottom: 16px;
    }
  }
`

const PricingPage = () => {
  const [billingPeriod, setBillingPeriod] = useState('YEAR')
  return(
  <Layout.Layout>
		<Layout.SEO refKey="pricing" />
    <Layout.HeroEmpty></Layout.HeroEmpty>
    <Ui.Section>
      <Ui.Container>
        <Ui.Row>
          <div className="col-md-12 text-center">
            <h1 className="font-heading text-4xl md:text-5xl leading-tight font-black text-dark">
              Trust your backups, focus on building awesome things.
            </h1>
            <p>Start with one backup on us, no credit card required - Plans scale with your growth.</p>

            <SPeriodSwitcher className="mt-16">
              <SPeriodSwitcherButton
                className={(billingPeriod === "MONTH" ? 'active' : '')}
                onClick={() => setBillingPeriod('MONTH')}>
                  Billed Monthly
              </SPeriodSwitcherButton>
              <SPeriodSwitcherButton
                className={(billingPeriod === "YEAR" ? 'active' : '')}
                onClick={() => setBillingPeriod('YEAR')}>
                Billed Yearly
              </SPeriodSwitcherButton>
            </SPeriodSwitcher>
            <PricingQuick className="mt-8" billingPeriod={billingPeriod} />

            <div className="text-center mt-20">
              <AnchorLink to="/pricing/#plan-comparison" title="Our plans"
                          className="text-dark mb-4 block font-bold text-xl">
                Compare plans <i className="far fa-arrow-right"></i>
              </AnchorLink>
              <div>All our plans have a 7-day trial, <span className="font-bold">no credit card required</span>.</div>
            </div>

            <div className="rounded-lg p-8 bg-gray-200 mt-20 text-left">
            <span
                className="rounded-full plan-label py-1 px-3 inline uppercase text-sm font-bold tracking-wider">Enterprise
            </span>
              {/*<span className="text-lg ml-3">From <span className="font-bold">$899<span className="text-base">/mo</span></span></span>*/}
              <div className="md:flex mt-3 justify-between">
                <div className="text-lg">
                  <p className="text-gray-600">
                    Looking for SLA, SSO, and compliance? Our Enterprise plans can be customized based on your business needs.
                  </p>
                  <ul className="mt-3">
                    <li><i className="fa fa-check text-green mr-2 text-xs"></i>Custom contracts & policies</li>
                    <li><i className="fa fa-check text-green mr-2 text-xs"></i>SAML Single-Sign On (SSO)</li>
                    <li><i className="fa fa-check text-green mr-2 text-xs"></i>Yearly Invoicing Option</li>
                    <li><i className="fa fa-check text-green mr-2 text-xs"></i>SLA for 99.99% Uptime</li>
                    <li><i className="fa fa-check text-green mr-2 text-xs"></i>Custom integrations</li>
                    <li><i className="fa fa-check text-green mr-2 text-xs"></i>Priority Support</li>
                  </ul>
                </div>
                <div>
                  <Button
                      className="block mt-3"
                      size="medium"
                      color="white"
                      href="/contact-us/"
                  >Schedule a demo</Button>
                </div>
              </div>
            </div>


          </div>

          <div className="mt-20 text-center" id="plan-comparison">
            <Ui.Typography tag="h2">
              Plan comparison
            </Ui.Typography>
            <PricingTable className="" billingPeriod={billingPeriod} />
            <p className="mt-8 text-sm italic">* You can request more SimpleStorage quota, on any plan, at any time.</p>
          </div>
        </Ui.Row>
      </Ui.Container>
    </Ui.Section>

    <Sections.SectionTestimonialExtended />

    <Sections.SectionFaq color="alt" faqs={[ 'pricing_backup_resource_definition', 'pricing_additional_backups','additional_storage', 'what_os_supported', 'is_secure', 'refund_policy', 'another_question', 'gdpr_compliance']}/>

  </Layout.Layout>)
}
export default PricingPage
